import React, { useState, useEffect } from 'react';

function FlashScreen({ isFlashing, mode }) {
  const [flashOn, setFlashOn] = useState(false);
  
  // Efecto para controlar el parpadeo
  useEffect(() => {
    if (!isFlashing) {
      setFlashOn(false);
      return;
    }
    
    // Intervalo para alternar el estado del flash cada 500ms (2 veces por segundo)
    const intervalId = setInterval(() => {
      setFlashOn(prevState => !prevState);
    }, 500);
    
    return () => clearInterval(intervalId);
  }, [isFlashing]);
  
  const getBackgroundColor = () => {
    // Solo mostrar color cuando flashOn es true y estamos en modo flashing
    const opacity = (isFlashing && flashOn) ? 0.5 : 0;
    if (mode === 'work') {
      return `rgba(0, 255, 0, ${opacity})`; // Green for work
    } else if (mode === 'rest') {
      return `rgba(255, 0, 0, ${opacity})`; // Red for rest
    } else if (mode === 'event') {
      return `rgba(0, 100, 255, ${opacity})`; // Blue for event
    } else {
      return 'transparent'; // No color for stopped mode
    }
  };

  const getBorderColor = () => {
    const opacity = (isFlashing && flashOn) ? 0.7 : 0;
    if (mode === 'work') {
      return `rgba(0, 100, 0, ${opacity})`; // Dark green for work extended period
    } else if (mode === 'rest') {
      return `rgba(255, 0, 0, ${opacity})`; // Red for rest period
    } else if (mode === 'event') {
      return `rgba(0, 50, 200, ${opacity})`; // Darker blue for event
    } else {
      return 'transparent';
    }
  };

  const flashStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: getBackgroundColor(),
    zIndex: 9999, // Place it above other components to ensure border is visible
    pointerEvents: 'none',
    transition: 'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    mixBlendMode: 'multiply', // This will blend the flash color with the background
    boxShadow: (isFlashing && flashOn) ? `inset 0 0 0 1cm ${getBorderColor()}` : 'none', // 1cm border that appears when flashing
  };

  return <div style={flashStyle} />;
}

export default FlashScreen;
