import Cookies from 'js-cookie';

const CONFIG_COOKIE_NAME = 'fmpomoplus_config';
const TODO_TASKS_COOKIE_NAME = 'fmpomoplus_todo_tasks';
const TODO_STYLES_COOKIE_NAME = 'fmpomoplus_todo_styles';
const EVENTS_COOKIE_NAME = 'fmpomoplus_events';

export const saveToCookie = (config) => {
  Cookies.set(CONFIG_COOKIE_NAME, JSON.stringify(config), { expires: 365 });
};

export const loadFromCookie = () => {
  const cookieData = Cookies.get(CONFIG_COOKIE_NAME);
  return cookieData ? JSON.parse(cookieData) : null;
};

export const getDefaultConfig = () => ({
  workDuration: 25,
  workExtension: 5,
  restDuration: 5,
  restExtension: 5,
  blink: true,
  volume: 50,
  audioAlerts: true,
  selectedTheme: 'theme-1'
});

export const mergeWithDefaultConfig = (savedConfig) => {
  const defaultConfig = getDefaultConfig();
  return { ...defaultConfig, ...savedConfig };
};

export const saveTodoTasksToCookie = (tasks) => {
  Cookies.set(TODO_TASKS_COOKIE_NAME, JSON.stringify(tasks), { expires: 365 });
};

export const loadTodoTasksFromCookie = () => {
  const cookieData = Cookies.get(TODO_TASKS_COOKIE_NAME);
  return cookieData ? JSON.parse(cookieData) : null;
};

export const saveTodoStylesToCookie = (styles) => {
  Cookies.set(TODO_STYLES_COOKIE_NAME, JSON.stringify(styles), { expires: 365 });
};

export const loadTodoStylesFromCookie = () => {
  const cookieData = Cookies.get(TODO_STYLES_COOKIE_NAME);
  return cookieData ? JSON.parse(cookieData) : null;
};

export const getDefaultTodoStyles = (theme) => ({
  top: { backgroundColor: theme.palette.error.light, color: theme.palette.error.contrastText, fontWeight: 'bold', fontSize: '16px' },
  middle: { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText, fontWeight: 'normal', fontSize: '14px' },
  bottom: { backgroundColor: theme.palette.success.light, color: theme.palette.success.contrastText, fontWeight: 'normal', fontSize: '12px' },
  completed: { backgroundColor: theme.palette.grey[200], color: theme.palette.text.secondary, fontWeight: 'normal', fontSize: '12px' }
});

// Funciones para guardar y cargar eventos
export const saveEventsToCookie = (events) => {
  Cookies.set(EVENTS_COOKIE_NAME, JSON.stringify(events), { expires: 365 });
};

export const loadEventsFromCookie = () => {
  const cookieData = Cookies.get(EVENTS_COOKIE_NAME);
  return cookieData ? JSON.parse(cookieData) : [];
};