const CALENDAR_CONFIG_KEY = 'calendarConfig';

export const getDefaultCalendarConfig = () => ({
  eventTitleFontSize: 16,
  timeDisplayFontSize: 14,
  durationFontSize: 14,
  workEventFontSize: 16,
});

export const loadCalendarConfig = () => {
  const savedConfig = localStorage.getItem(CALENDAR_CONFIG_KEY);
  return savedConfig ? JSON.parse(savedConfig) : getDefaultCalendarConfig();
};

export const saveCalendarConfig = (config) => {
  localStorage.setItem(CALENDAR_CONFIG_KEY, JSON.stringify(config));
};