import React from 'react';
import { Typography, TextField, Box } from '@material-ui/core';

function CalendarConfig({ calendarConfig, onCalendarConfigChange }) {
  const handleStyleChange = (styleKey, value) => {
    onCalendarConfigChange({
      ...calendarConfig,
      [styleKey]: value,
    });
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">Configuración de fuentes del calendario</Typography>
      </Box>
      
      <TextField
        label="Tamaño de fuente del título de evento (px)"
        type="number"
        value={calendarConfig?.eventTitleFontSize || 16}
        onChange={(e) => handleStyleChange('eventTitleFontSize', Number(e.target.value))}
        fullWidth
        margin="normal"
        InputProps={{ inputProps: { min: 8, max: 32 } }}
      />
      
      <TextField
        label="Tamaño de fuente de hora y minutos (px)"
        type="number"
        value={calendarConfig?.timeDisplayFontSize || 14}
        onChange={(e) => handleStyleChange('timeDisplayFontSize', Number(e.target.value))}
        fullWidth
        margin="normal"
        InputProps={{ inputProps: { min: 8, max: 32 } }}
      />
      
      <TextField
        label="Tamaño de fuente de duración del evento (px)"
        type="number"
        value={calendarConfig?.durationFontSize || 14}
        onChange={(e) => handleStyleChange('durationFontSize', Number(e.target.value))}
        fullWidth
        margin="normal"
        InputProps={{ inputProps: { min: 8, max: 32 } }}
      />
      
      <TextField
        label="Tamaño de fuente de eventos automáticos de trabajo (px)"
        type="number"
        value={calendarConfig?.workEventFontSize || 16}
        onChange={(e) => handleStyleChange('workEventFontSize', Number(e.target.value))}
        fullWidth
        margin="normal"
        InputProps={{ inputProps: { min: 8, max: 32 } }}
      />
    </div>
  );
}

export default CalendarConfig;