import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Fab, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import StopIcon from '@material-ui/icons/Stop';
import EventList from './EventList';
import { saveEventsToCookie, loadEventsFromCookie } from '../cookieManager';
import FlashScreen from './FlashScreen';
import { playEventSound } from '../audioManager';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  addButton: {
    marginLeft: 'auto',
  },
  stopAlarmButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: '100%',
    padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function EventCalendar() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [eventAlarmActive, setEventAlarmActive] = useState(false);
  const [activeEventId, setActiveEventId] = useState(null);
  // Registro de alarmas que ya han sido activadas (para evitar activaciones repetidas)
  const [triggeredAlarms, setTriggeredAlarms] = useState({});

  // Cargar eventos desde la cookie al iniciar
  useEffect(() => {
    const savedEvents = loadEventsFromCookie();
    if (savedEvents && savedEvents.length > 0) {
      setEvents(savedEvents);
    }
  }, []);

  // Verificar si algún evento con alarma activada debe sonar
  useEffect(() => {
    const checkEventAlarms = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTimeInMinutes = currentHour * 60 + currentMinute;
      const today = now.toDateString(); // Fecha actual para identificar el día
      
      // Buscar eventos con alarma activada que deban sonar ahora
      events.forEach(event => {
        if (event.alarmEnabled && event.time) {
          const [eventHour, eventMinute] = event.time.split(':').map(Number);
          const eventTimeInMinutes = eventHour * 60 + eventMinute;
          
          // Crear un identificador único para este evento en este día
          const alarmId = `${event.id}-${today}`;
          
          // Si es exactamente la hora del evento (con margen de 1 minuto) y no ha sido activada hoy
          if (Math.abs(currentTimeInMinutes - eventTimeInMinutes) <= 1 && !triggeredAlarms[alarmId]) {
            if (!eventAlarmActive) {
              // Activar la alarma
              setEventAlarmActive(true);
              setActiveEventId(event.id);
              playEventSound();
              
              // Registrar que esta alarma ya ha sido activada hoy
              setTriggeredAlarms(prev => ({
                ...prev,
                [alarmId]: true
              }));
            }
          }
        }
      });
    };
    
    // Verificar cada minuto
    const intervalId = setInterval(checkEventAlarms, 60000);
    
    // Verificar inmediatamente al cargar
    checkEventAlarms();
    
    return () => clearInterval(intervalId);
  }, [events, eventAlarmActive, triggeredAlarms]);

  // Guardar eventos en la cookie cada vez que cambian
  useEffect(() => {
    saveEventsToCookie(events);
  }, [events]);

  const addEvent = (event) => {
    const newEvent = { ...event, id: Date.now() };
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const deleteEvent = (id) => {
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
  };

  const updateEvent = (updatedEvent) => {
    setEvents((prevEvents) => 
      prevEvents.map((event) => 
        event.id === updatedEvent.id ? updatedEvent : event
      )
    );
  };

  const handleAddButtonClick = () => {
    setIsAddingNew(true);
  };

  const handleCancelNew = () => {
    setIsAddingNew(false);
  };

  const handleSaveNew = (newEvent) => {
    // Eliminar la propiedad isNew y el id temporal
    const { isNew, ...eventData } = newEvent;
    // Añadir el evento real
    addEvent(eventData);
    
    // Desactivamos el modo de añadir nuevo evento
    // Esto solo se ejecutará cuando se guarde con el botón "Guardar"
    // ya que cuando se guarda con ENTER, se llama a handleSaveAndAddNew
    setIsAddingNew(false);
  };

  // Función para guardar un evento y añadir uno nuevo inmediatamente
  const handleSaveAndAddNew = (newEvent) => {
    if (newEvent) {
      // Si se proporciona un evento, guardarlo primero
      if (newEvent.isNew) {
        // Si es un evento nuevo, usar addEvent
        const { isNew, ...eventData } = newEvent;
        addEvent(eventData);
      } else {
        // Si es un evento existente, usar updateEvent
        updateEvent(newEvent);
      }
    }
    
    // Forzar un retraso para asegurar que el estado se actualiza correctamente
    setTimeout(() => {
      // Activar el modo de añadir nuevo evento (o mantenerlo activo)
      setIsAddingNew(false); // Primero desactivamos para forzar un re-render
      setTimeout(() => {
        setIsAddingNew(true); // Luego activamos de nuevo
      }, 10);
    }, 10);
  };

  // Función para guardar un evento existente y abrir un nuevo evento
  const handleSaveExistingAndAddNew = () => {
    // Simplemente activar el modo de añadir nuevo
    setIsAddingNew(true);
  };

  // Método para detener la alarma activa
  const handleStopAlarm = () => {
    // Detener el estado de alarma activa
    setEventAlarmActive(false);
    setActiveEventId(null);
    
    // Detener el sonido actual
    if (window.audioManager) {
      window.audioManager.stopCurrentSound();
    }
  };

  // Crear un evento temporal para el modo de edición
  const getNewEventTemplate = () => {
    return {
      id: `new-event-temp-${Date.now()}`, // ID único basado en timestamp
      time: '12:00',
      duration: 30,
      description: '',
      isNew: true
    };
  };

  return (
    <div className={classes.root}>
      {/* Componente FlashScreen para el parpadeo azul cuando hay una alarma activa */}
      <FlashScreen isFlashing={eventAlarmActive} mode="event" />
      
      <Box className={classes.headerContainer}>
        <Typography variant="h5" className={classes.title}>
          Calendario
        </Typography>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          className={classes.addButton}
          onClick={handleAddButtonClick}
          disabled={isAddingNew}
        >
          <AddIcon />
        </Fab>
      </Box>
      
      {/* Botón para detener la alarma, solo visible cuando hay una alarma activa */}
      {eventAlarmActive && (
        <Button
          variant="contained"
          startIcon={<StopIcon />}
          className={classes.stopAlarmButton}
          onClick={handleStopAlarm}
        >
          Detener Alarma
        </Button>
      )}
      
      <EventList
        events={events}
        onDeleteEvent={deleteEvent}
        onUpdateEvent={updateEvent}
        isAddingNew={isAddingNew}
        newEventTemplate={isAddingNew ? getNewEventTemplate() : null}
        onSaveNew={handleSaveNew}
        onCancelNew={handleCancelNew}
        onSaveAndAddNew={handleSaveAndAddNew}
        onSaveExistingAndAddNew={handleSaveExistingAndAddNew}
      />
    </div>
  );
}

export default EventCalendar;
