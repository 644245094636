import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import { loadCalendarConfig } from '../calendarConfigManager';

const useStyles = makeStyles((theme) => {
  // Cargar la configuración del calendario
  const calendarConfig = loadCalendarConfig();
  
  return {
    workItem: {
      backgroundColor: '#e0f7e0', // Verde claro
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 'none',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      cursor: 'default', // Indica que no es interactivo
    },
    activeWorkItem: {
      backgroundColor: '#e0f7e0', // Mantener el mismo fondo
      border: '2px solid #2e7d32', // Borde verde más oscuro cuando está activo
      borderRadius: '4px', // Bordes redondeados
      borderBottom: `2px solid #2e7d32`,
      '&:last-child': {
        borderBottom: '2px solid #2e7d32',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      cursor: 'default',
    },
    durationText: {
      fontWeight: 'bold',
      fontSize: `${calendarConfig.workEventFontSize || 16}px`,
      color: '#2e7d32', // Verde más oscuro para el texto
      textAlign: 'center',
    },
    remainingText: {
      fontSize: `${calendarConfig.workEventFontSize - 2 || 14}px`,
      color: '#1b5e20', // Verde más oscuro para resaltar
      fontWeight: 'bold', // Texto en negrita para resaltar
      marginTop: theme.spacing(1),
      textAlign: 'center',
    }
  };
});

function WorkEventItem({ duration, startTime, nextEventTime }) {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  
  // Verificar si el evento está activo según la hora actual
  useEffect(() => {
    const checkIfActive = () => {
      if (!startTime) return false;
      
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      
      // Convertir la hora de inicio a minutos desde medianoche
      const [startHour, startMinute] = startTime.split(':').map(Number);
      const startTimeInMinutes = startHour * 60 + startMinute;
      
      // Convertir la hora del siguiente evento a minutos desde medianoche
      const [nextHour, nextMinute] = nextEventTime ? nextEventTime.split(':').map(Number) : [24, 0];
      const nextTimeInMinutes = nextHour * 60 + nextMinute;
      
      // Convertir la hora actual a minutos desde medianoche
      const currentTimeInMinutes = currentHour * 60 + currentMinute;
      
      // Calcular minutos restantes hasta el siguiente evento
      const remaining = nextTimeInMinutes - currentTimeInMinutes;
      setRemainingMinutes(remaining > 0 ? remaining : 0);
      
      // El evento está activo si la hora actual está entre la hora de inicio y la hora del siguiente evento
      return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < nextTimeInMinutes;
    };
    
    // Verificar inicialmente
    setIsActive(checkIfActive());
    
    // Configurar un intervalo para verificar cada minuto
    const intervalId = setInterval(() => {
      setIsActive(checkIfActive());
    }, 60000); // 60000 ms = 1 minuto
    
    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar
  }, [startTime, nextEventTime]);
  
  return (
    <ListItem className={isActive ? classes.activeWorkItem : classes.workItem}>
      <Typography className={classes.durationText}>
        {duration} minutos
      </Typography>
      {isActive && (
        <Typography className={classes.remainingText}>
          {remainingMinutes} minutos restantes
        </Typography>
      )}
    </ListItem>
  );
}

export default WorkEventItem;