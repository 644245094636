import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCalendarConfig } from '../calendarConfigManager';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  TextField,
  Box,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';

const useStyles = makeStyles((theme) => {
  // Cargar la configuración del calendario
  const calendarConfig = loadCalendarConfig();
  
  return {
    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    activeEvent: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiTypography-colorTextSecondary': {
        color: theme.palette.primary.contrastText,
      },
    },
    time: {
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
      fontSize: `${calendarConfig.timeDisplayFontSize || 14}px`,
    },
    duration: {
      color: theme.palette.text.secondary,
      fontSize: `${calendarConfig.durationFontSize || 14}px`,
    },
    eventTitle: {
      fontSize: `${calendarConfig.eventTitleFontSize || 16}px`,
    },
    editForm: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: '100%',
    },
    hideNumberInputArrows: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
        textAlign: 'center',
        fontSize: '0.85rem',
      },
    },
    separator: {
      margin: '0 2px',
      fontWeight: 'bold',
      fontSize: '0.85rem',
    },
    actionButtons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    clickable: {
      cursor: 'pointer',
    }
  };
});

function EventItem({
  event,
  onDelete,
  onUpdate,
  onCancelEdit,
  isEditing: initialIsEditing = false,
  onSaveAndAddNew
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  
  // Actualizar isEditing cuando cambia initialIsEditing
  useEffect(() => {
    setIsEditing(initialIsEditing);
  }, [initialIsEditing]);
  const [isActive, setIsActive] = useState(false);
  
  // Estados para los campos editables
  const [hour, setHour] = useState(event.time ? event.time.split(':')[0] : '');
  const [minute, setMinute] = useState(event.time ? event.time.split(':')[1] : '00');
  const [duration, setDuration] = useState(String(event.duration));
  const [description, setDescription] = useState(event.description);
  const [alarmEnabled, setAlarmEnabled] = useState(event.alarmEnabled !== undefined ? event.alarmEnabled : false);
  
  // Referencia al campo de hora para enfocarlo automáticamente
  const hourInputRef = useRef(null);

  // Enfocar el campo de hora cuando se inicia el modo de edición o cambia el evento
  useEffect(() => {
    // Siempre intentar enfocar el campo de hora cuando el componente se monta o actualiza
    // y está en modo edición
    const focusHourInput = () => {
      if (isEditing && hourInputRef.current) {
        hourInputRef.current.focus();
      }
    };

    // Intentar enfocar inmediatamente
    focusHourInput();
    
    // Y también después de varios retrasos para asegurar que el DOM se ha actualizado
    const timerId1 = setTimeout(focusHourInput, 50);
    const timerId2 = setTimeout(focusHourInput, 100);
    const timerId3 = setTimeout(focusHourInput, 200);
    
    return () => {
      clearTimeout(timerId1);
      clearTimeout(timerId2);
      clearTimeout(timerId3);
    };
  }, [isEditing, event.id]);

  // Este efecto se ejecuta solo cuando el componente se monta
  useEffect(() => {
    // Si el evento es nuevo y está en modo edición, enfocar el campo de hora
    if (event.isNew && isEditing && hourInputRef.current) {
      const timerId = setTimeout(() => {
        hourInputRef.current.focus();
      }, 300);
      
      return () => clearTimeout(timerId);
    }
  }, []);

  // Actualizar estados cuando cambia el evento (útil para el evento temporal)
  useEffect(() => {
    setHour(event.time ? event.time.split(':')[0] : '');
    setMinute(event.time ? event.time.split(':')[1] : '00');
    setDuration(String(event.duration));
    setDescription(event.description);
    setAlarmEnabled(event.alarmEnabled !== undefined ? event.alarmEnabled : false);
  }, [event]);

  // Verificar si el evento está activo según la hora actual
  useEffect(() => {
    const checkIfActive = () => {
      if (!event.time) return false;
      
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      
      const [eventHour, eventMinute] = event.time.split(':').map(Number);
      
      // Convertir todo a minutos para facilitar la comparación
      const currentTimeInMinutes = currentHour * 60 + currentMinute;
      const eventStartInMinutes = eventHour * 60 + eventMinute;
      const eventEndInMinutes = eventStartInMinutes + event.duration;
      
      // El evento está activo si la hora actual está entre la hora de inicio y fin
      return currentTimeInMinutes >= eventStartInMinutes && currentTimeInMinutes < eventEndInMinutes;
    };
    
    // Verificar inicialmente
    setIsActive(checkIfActive());
    
    // Configurar un intervalo para verificar cada minuto
    const intervalId = setInterval(() => {
      setIsActive(checkIfActive());
    }, 60000); // 60000 ms = 1 minuto
    
    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar
  }, [event.time, event.duration]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    // Restaurar valores originales
    setHour(event.time ? event.time.split(':')[0] : '');
    setMinute(event.time ? event.time.split(':')[1] : '00');
    setDuration(String(event.duration));
    setDescription(event.description);
    setAlarmEnabled(event.alarmEnabled !== undefined ? event.alarmEnabled : false);
    setIsEditing(false);
    
    // Si es un evento nuevo y se cancela, notificar al componente padre
    if (event.isNew && onCancelEdit) {
      onCancelEdit();
    }
  };

  const handleSave = () => {
    // Validar datos
    if ((hour && (parseInt(hour) < 0 || parseInt(hour) > 23)) ||
        (hour && (parseInt(minute) < 0 || parseInt(minute) > 59)) ||
        parseInt(duration) <= 0 ||
        !description.trim()) {
      return; // Datos inválidos
    }

    // Crear el objeto actualizado
    const updatedEvent = {
      ...event,
      time: hour ? `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}` : '',
      duration: parseInt(duration),
      description: description.trim(),
      alarmEnabled: alarmEnabled
    };

    // Llamar a la función de actualización
    onUpdate(updatedEvent);
    
    // Siempre cerrar el modo de edición al guardar con el botón
    setIsEditing(false);
  };

  // Manejador para el campo de hora
  const handleHourChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      setHour('');
    } else {
      let num = parseInt(value, 10);
      if (!isNaN(num)) {
        if (num < 0) num = 0;
        if (num > 23) num = 23;
        setHour(String(num).padStart(2, '0'));
      }
    }
  };

  // Manejador de teclas para el formulario
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      // Validar datos
      if ((hour && (parseInt(hour) < 0 || parseInt(hour) > 23)) ||
          (hour && (parseInt(minute) < 0 || parseInt(minute) > 59)) ||
          parseInt(duration) <= 0 ||
          !description.trim()) {
        return; // Datos inválidos
      }

      // Crear el objeto actualizado
      const updatedEvent = {
        ...event,
        time: hour ? `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}` : '',
        duration: parseInt(duration),
        description: description.trim(),
        alarmEnabled: alarmEnabled
      };
      
      // Siempre usar onSaveAndAddNew si está disponible, independientemente de si es un evento nuevo o existente
      if (onSaveAndAddNew) {
        // Cerrar el modo de edición para este evento
        setIsEditing(false);
        // Llamar a la función que guarda y abre uno nuevo
        onSaveAndAddNew(updatedEvent);
      } else {
        // Si no hay función onSaveAndAddNew, guardar normalmente
        onUpdate(updatedEvent);
        setIsEditing(false);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      handleCancel();
    }
  };

  if (isEditing) {
    return (
      <ListItem className={classes.listItem}>
        <div className={classes.editForm} onKeyDown={handleKeyDown}>
          {/* Campo para la hora */}
          <TextField
            inputRef={hourInputRef}
            className={classes.hideNumberInputArrows}
            placeholder="Hora"
            type="number"
            value={hour}
            onChange={handleHourChange}
            InputProps={{ inputProps: { min: 0, max: 23, style: { width: '40px' } } }}
          />
          
          {/* Separador ":" */}
          <Typography className={classes.separator}>:</Typography>
          
          {/* Campo para los minutos */}
          <TextField
            className={classes.hideNumberInputArrows}
            placeholder="Min"
            type="number"
            value={minute}
            onChange={(e) => {
              const val = e.target.value;
              if (val === '' || (parseInt(val) >= 0 && parseInt(val) <= 59)) {
                setMinute(val.padStart(2, '0'));
              }
            }}
            InputProps={{ inputProps: { min: 0, max: 59, step: 15, style: { width: '40px' } } }}
          />
          
          {/* Separador "/" */}
          <Typography className={classes.separator}>/</Typography>
          
          {/* Campo para la duración */}
          <Box display="flex" alignItems="center">
            <TextField
              className={classes.hideNumberInputArrows}
              placeholder="Duración"
              type="number"
              value={duration}
              onChange={(e) => {
                const val = e.target.value;
                if (val === '' || parseInt(val) >= 0) {
                  setDuration(val);
                }
              }}
              InputProps={{ inputProps: { min: 0, step: 15, style: { width: '40px' } } }}
            />
            {/* Literal "m" después de duración */}
            <Typography className={classes.separator}>m</Typography>
          </Box>
          
          {/* Campo para el evento */}
          <TextField
            placeholder="Evento"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </div>
        <ListItemSecondaryAction className={classes.actionButtons}>
          <IconButton edge="end" aria-label="save" onClick={handleSave}>
            <SaveIcon />
          </IconButton>
          <IconButton edge="end" aria-label="cancel" onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  // Manejador para cambiar el estado de la alarma
  const handleToggleAlarm = () => {
    const updatedEvent = {
      ...event,
      alarmEnabled: !alarmEnabled
    };
    
    // Actualizar el estado local
    setAlarmEnabled(!alarmEnabled);
    
    // Llamar a la función de actualización
    onUpdate(updatedEvent);
  };

  return (
    <ListItem className={`${classes.listItem} ${isActive ? classes.activeEvent : ''}`}>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography component="span" variant="body2" className={classes.time}>
              {event.time}
            </Typography>
            <Typography component="span" variant="body2" className={classes.duration}>
              ({event.duration} min)
            </Typography>
          </React.Fragment>
        }
        secondary={<span className={classes.eventTitle}>{event.description}</span>}
        onClick={handleEdit}
        className={classes.clickable}
      />
      <ListItemSecondaryAction>
        <Tooltip title={alarmEnabled ? "Desactivar alarma" : "Activar alarma"}>
          <IconButton
            edge="end"
            aria-label="toggle-alarm"
            onClick={handleToggleAlarm}
            color={alarmEnabled ? "primary" : "default"}
          >
            {alarmEnabled ? <AlarmIcon /> : <AlarmOffIcon color="disabled" />}
          </IconButton>
        </Tooltip>
        <IconButton edge="end" aria-label="edit" onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default EventItem;
