// File: App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, CssBaseline, Grid, Button } from '@material-ui/core';
import Title from './components/Title.js';
import Timer from './components/Timer.js';
import ActionButtons from './components/ActionButtons.js';
import Statistics from './components/Statistics.js';
import ConfigButtons from './components/ConfigButtons.js';
import ConfigDialog from './components/ConfigDialog.js';
import FlashScreen from './components/FlashScreen.js';
import TodoList from './components/TodoList.js';
import EventCalendar from './components/EventCalendar';
import { loadSounds, playWorkSound, playRestSound, playExtWorkSound, playExtRestSound } from './audioManager.js';
import { getTheme, themeNames } from './themeManager.js';
import { saveToCookie, loadFromCookie, getDefaultConfig, mergeWithDefaultConfig } from './cookieManager.js';
import { loadTodoConfig, saveTodoConfig, getDefaultTodoConfig } from './todoConfigManager.js';
import { loadCalendarConfig, saveCalendarConfig, getDefaultCalendarConfig } from './calendarConfigManager.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0, // Remove padding to occupy full width
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%', // Ensure full width
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  todoContainer: {
    width: '35%', // TodoList takes 35% of width
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      marginRight: 0, // Remove right margin
    },
  },
  timerContainer: {
    width: '30%', // Timer takes 30% of width
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      marginRight: 0, // Remove right margin
    },
  },
  calendarContainer: {
    width: '35%', // Calendar takes 35% of width
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  paper: {
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));
function App() {
  const [config, setConfig] = useState(getDefaultConfig());
  const [todoConfig, setTodoConfig] = useState(loadTodoConfig() || getDefaultTodoConfig());
  const [calendarConfig, setCalendarConfig] = useState(loadCalendarConfig() || getDefaultCalendarConfig());
  const theme = getTheme(config.selectedTheme);
  const classes = useStyles(theme);
  const [timerValue, setTimerValue] = useState(0);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [mode, setMode] = useState('stopped');
  const [isFlashing, setIsFlashing] = useState(false);
  const [isCountingUp, setIsCountingUp] = useState(false);
  const isCountingUpRef = useRef(isCountingUp);
  const timerRef = useRef(null);
  const extensionRef = useRef(null);
  const flashingRef = useRef(null);
  const alertTriggeredRef = useRef(false);

  // Actualizar la referencia cada vez que isCountingUp cambie
  useEffect(() => {
    isCountingUpRef.current = isCountingUp;
  }, [isCountingUp]);

  useEffect(() => {
    loadSounds();
    const savedConfig = loadFromCookie();
    if (savedConfig) {
      setConfig(mergeWithDefaultConfig(savedConfig));
    }
  }, []);

  useEffect(() => {
    saveToCookie(config);
  }, [config]);

  const formatTime = (seconds) => {
    const mins = Math.floor(Math.abs(seconds) / 60).toString().padStart(2, '0');
    const secs = (Math.abs(seconds) % 60).toString().padStart(2, '0');
    return `${seconds < 0 ? '-' : ''}${mins}:${secs}`;
  };

  const playAlertSound = useCallback((currentMode, isExtension) => {
    if (config.audioAlerts) {
      if (isExtension) {
        currentMode === 'work' ? playExtWorkSound() : playExtRestSound();
      } else {
        currentMode === 'work' ? playWorkSound() : playRestSound();
      }
    }
  }, [config.audioAlerts]);

  const triggerAlert = useCallback((currentMode) => {
    playAlertSound(currentMode, false);
    if (config.blink) {
      setIsFlashing(true);
    }
  }, [config.blink, playAlertSound]);

  const startExtensionAlerts = useCallback((currentMode) => {
    if (extensionRef.current) clearInterval(extensionRef.current);
    if (flashingRef.current) clearInterval(flashingRef.current);
    
    // No reproducir el sonido de extensión inmediatamente
    setIsFlashing(true);

    // Iniciar el parpadeo continuo
    flashingRef.current = setInterval(() => {
      setIsFlashing(prev => !prev);
    }, 1000);

    const extensionDuration = currentMode === 'work' ? config.workExtension : config.restExtension;
    extensionRef.current = setInterval(() => {
      // Reproducir el sonido de extensión en cada intervalo adicional
      playAlertSound(currentMode, true);
    }, extensionDuration * 60 * 1000); // Cada "extensionDuration" minutos

  }, [config.workExtension, config.restExtension, playAlertSound]);

  const stopTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    if (extensionRef.current) clearInterval(extensionRef.current);
    if (flashingRef.current) clearInterval(flashingRef.current);
    setIsCountingUp(false);
    isCountingUpRef.current = false; // Actualizar la referencia
    setIsFlashing(false);
    alertTriggeredRef.current = false; // Resetear la alerta al detener el temporizador
  }, []);

  const startTimer = useCallback((duration, currentMode) => {
    if (timerRef.current) clearInterval(timerRef.current);
    const startTime = Date.now();
    setIsCountingUp(false);
    setIsFlashing(false);
    alertTriggeredRef.current = false; // Resetear la alerta al iniciar el temporizador

    timerRef.current = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      if (elapsedTime < duration) {
        setTimerValue(duration - elapsedTime);
      } else {
        if (!isCountingUpRef.current) { // Usar la referencia aquí
          setIsCountingUp(true);
          isCountingUpRef.current = true; // Actualizar la referencia
          if (!alertTriggeredRef.current) {
            triggerAlert(currentMode); // Reproducir el sonido principal una vez
            startExtensionAlerts(currentMode);
            alertTriggeredRef.current = true;
          }
        }
        setTimerValue(elapsedTime - duration);
      }
    }, 1000);
  }, [startExtensionAlerts, triggerAlert]);

  const handleWork = () => {
    stopTimer();
    setMode('work');
    setTimerValue(config.workDuration * 60);
    startTimer(config.workDuration * 60, 'work');
  };

  const handleRest = () => {
    stopTimer();
    setMode('rest');
    setTimerValue(config.restDuration * 60);
    startTimer(config.restDuration * 60, 'rest');
  };

  const handleStop = () => {
    setMode('stopped');
    stopTimer();
    setTimerValue(0);
  };

  const handleReset = () => {
    updateTotalWork(0);
    updateTotalRest(0);
  };

  useEffect(() => {
    if (mode !== 'stopped' && timerValue > 0) {
      const totalKey = mode === 'work' ? 'totalWork' : 'totalRest';
      setConfig(prevConfig => ({
        ...prevConfig,
        [totalKey]: (prevConfig[totalKey] || 0) + 1
      }));
    }
  }, [timerValue, mode]);

  const updateTotalWork = (newTotal) => setConfig(prevConfig => ({ ...prevConfig, totalWork: newTotal }));
  const updateTotalRest = (newTotal) => setConfig(prevConfig => ({ ...prevConfig, totalRest: newTotal }));
  const handleConfigChange = (newConfig) => setConfig(newConfig);
  const handleTodoConfigChange = (newTodoConfig) => {
    setTodoConfig(newTodoConfig);
    saveTodoConfig(newTodoConfig);
  };

  const handleCalendarConfigChange = (newCalendarConfig) => {
    setCalendarConfig(newCalendarConfig);
    saveCalendarConfig(newCalendarConfig);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container className={classes.root} maxWidth={false} disableGutters={true}>
        <div className={classes.content}>
          <div className={classes.todoContainer}>
            <Paper className={classes.paper}>
              <TodoList
                theme={theme}
                todoConfig={todoConfig}
                onOpenConfigDialog={() => setIsConfigOpen(true)}
              />
            </Paper>
          </div>
          <div className={classes.timerContainer}>
            <Paper className={classes.paper}>
              <Title />
              <Timer
                value={formatTime(timerValue)}
                mode={mode}
                isCountingUp={isCountingUp}
                isFlashing={isFlashing && config.blink}
              />
              <ActionButtons onWork={handleWork} onRest={handleRest} onStop={handleStop} mode={mode} />
              <Statistics
                totalWork={config.totalWork || 0}
                totalRest={config.totalRest || 0}
                updateTotalWork={updateTotalWork}
                updateTotalRest={updateTotalRest}
              />
              <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: 16 }}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    disabled={mode !== 'stopped'}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <ConfigButtons onConfigOpen={() => setIsConfigOpen(true)} />
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div className={classes.calendarContainer}>
            <Paper className={classes.paper}>
              <EventCalendar />
            </Paper>
          </div>
        </div>
        <ConfigDialog
          open={isConfigOpen}
          onClose={() => setIsConfigOpen(false)}
          config={config}
          onConfigChange={handleConfigChange}
          themeNames={themeNames}
          todoConfig={todoConfig}
          onTodoConfigChange={handleTodoConfigChange}
          calendarConfig={calendarConfig}
          onCalendarConfigChange={handleCalendarConfigChange}
        />
        <FlashScreen isFlashing={isFlashing && config.blink} mode={mode} />
      </Container>
    </ThemeProvider>
  );
}

export default App;
