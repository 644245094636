import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Typography,
  Box
} from '@material-ui/core';
import EventItem from './EventItem';
import WorkEventItem from './WorkEventItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    minHeight: '100px',
  },
  emptyMessage: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  newEventContainer: {
    marginBottom: theme.spacing(2),
  }
}));

function EventList({ 
  events, 
  onDeleteEvent, 
  onUpdateEvent,
  isAddingNew,
  newEventTemplate,
  onSaveNew,
  onCancelNew,
  onSaveAndAddNew,
  onSaveExistingAndAddNew
}) {
  const classes = useStyles();
  
  // Ordenar eventos: los eventos sin hora se colocan al inicio
  const sortedEvents = [...events].sort((a, b) => {
    if (!a.time && !b.time) return 0;
    if (!a.time) return -1;
    if (!b.time) return 1;
    return a.time.localeCompare(b.time);
  });

  // Función para calcular los eventos de trabajo entre eventos programados
  const calculateWorkEvents = (events) => {
    // Filtrar solo eventos con hora (con tiempo definido)
    const timedEvents = events.filter(event => event.time);
    
    // Si hay menos de 2 eventos con hora, no hay espacios entre eventos
    if (timedEvents.length < 2) return [];
    
    // Ordenar eventos por hora
    const sortedTimedEvents = [...timedEvents].sort((a, b) => a.time.localeCompare(b.time));
    
    const workEvents = [];
    
    // Calcular espacios entre eventos consecutivos
    for (let i = 0; i < sortedTimedEvents.length - 1; i++) {
      const currentEvent = sortedTimedEvents[i];
      const nextEvent = sortedTimedEvents[i + 1];
      
      // Convertir horas a minutos para facilitar cálculos
      const [currentHour, currentMinute] = currentEvent.time.split(':').map(Number);
      const [nextHour, nextMinute] = nextEvent.time.split(':').map(Number);
      
      const currentEndMinutes = (currentHour * 60 + currentMinute) + currentEvent.duration;
      const nextStartMinutes = nextHour * 60 + nextMinute;
      
      // Calcular la duración del espacio entre eventos
      const gapDuration = nextStartMinutes - currentEndMinutes;
      
      // Solo crear evento de trabajo si el espacio es de al menos 30 minutos
      if (gapDuration >= 30) {
        workEvents.push({
          id: `work-${currentEvent.id}-${nextEvent.id}`,
          isWorkEvent: true,
          duration: gapDuration,
          // Calcular la hora de inicio del evento de trabajo (para ordenarlo correctamente)
          time: `${String(Math.floor(currentEndMinutes / 60)).padStart(2, '0')}:${String(currentEndMinutes % 60).padStart(2, '0')}`,
          // Guardar la hora de inicio real del evento de trabajo
          startTime: `${String(Math.floor(currentEndMinutes / 60)).padStart(2, '0')}:${String(currentEndMinutes % 60).padStart(2, '0')}`,
          // Guardar la hora del siguiente evento
          nextEventTime: nextEvent.time
        });
      }
    }
    
    return workEvents;
  };
  
  // Obtener eventos de trabajo
  const workEvents = calculateWorkEvents(events);
  
  // Combinar eventos normales y eventos de trabajo
  const allEvents = [...sortedEvents, ...workEvents].sort((a, b) => {
    if (!a.time && !b.time) return 0;
    if (!a.time) return -1;
    if (!b.time) return 1;
    return a.time.localeCompare(b.time);
  });

  // Función para manejar la acción de guardar un evento existente y añadir uno nuevo
  const handleSaveExistingAndAddNew = (updatedEvent) => {
    // Primero actualizamos el evento existente
    onUpdateEvent(updatedEvent);
    // Luego activamos el modo de añadir nuevo evento
    onSaveExistingAndAddNew();
  };

  return (
    <div className={classes.root}>
      {/* Si estamos añadiendo un nuevo evento, mostrar el formulario de edición */}
      {isAddingNew && newEventTemplate && (
        <Box className={classes.newEventContainer}>
          <EventItem
            key={newEventTemplate.id} // Asegurar que React crea un nuevo componente
            event={newEventTemplate}
            isEditing={true} // Forzar modo edición para el nuevo evento
            initialIsEditing={true} // Asegurar que se inicializa en modo edición
            onUpdate={onSaveNew}
            onCancelEdit={onCancelNew}
            onSaveAndAddNew={onSaveAndAddNew}
          />
        </Box>
      )}
      
      {events.length === 0 && !isAddingNew ? (
        <Typography variant="body2" className={classes.emptyMessage}>
          No hay eventos programados para hoy.
        </Typography>
      ) : (
        <List className={classes.root}>
          {/* Lista de eventos existentes y eventos de trabajo */}
          {allEvents.map((event) =>
            event.isWorkEvent ? (
              <WorkEventItem
                key={event.id}
                duration={event.duration}
                startTime={event.startTime}
                nextEventTime={event.nextEventTime}
              />
            ) : (
              <EventItem
                key={event.id}
                event={event}
                onDelete={() => onDeleteEvent(event.id)}
                onUpdate={(updatedEvent) => onUpdateEvent(updatedEvent)}
                onSaveAndAddNew={handleSaveExistingAndAddNew}
              />
            )
          )}
        </List>
      )}
    </div>
  );
}

export default EventList;
