import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Slider,
  Typography,
  AppBar,
} from '@material-ui/core';
import TodoListConfig from './TodoListConfig';
import CalendarConfig from './CalendarConfig';

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function ConfigDialog({
  open,
  onClose,
  config,
  onConfigChange,
  themeNames,
  todoConfig,
  onTodoConfigChange,
  calendarConfig,
  onCalendarConfigChange
}) {
  const [tabValue, setTabValue] = useState(0);
  const [tempConfig, setTempConfig] = useState(config);
  const [tempTodoConfig, setTempTodoConfig] = useState(todoConfig);
  const [tempCalendarConfig, setTempCalendarConfig] = useState(calendarConfig);

  useEffect(() => {
    setTempConfig(config);
    setTempTodoConfig(todoConfig);
    setTempCalendarConfig(calendarConfig);
  }, [config, todoConfig, calendarConfig]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSave = useCallback(() => {
    onConfigChange(tempConfig);
    onTodoConfigChange(tempTodoConfig);
    onCalendarConfigChange(tempCalendarConfig);
    onClose();
  }, [onConfigChange, onTodoConfigChange, onCalendarConfigChange, tempConfig, tempTodoConfig, tempCalendarConfig, onClose]);

  const handleConfigChange = (key, value) => {
    setTempConfig(prevConfig => ({
      ...prevConfig,
      [key]: value
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Configuración</DialogTitle>
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="General" />
          <Tab label="Alerts" />
          <Tab label="Appearance" />
          <Tab label="ToDo List" />
          <Tab label="Calendar" />
        </Tabs>
      </AppBar>
      <DialogContent>
        <TabPanel value={tabValue} index={0}>
          <TextField
            label="Work Duration (minutes)"
            type="number"
            value={tempConfig.workDuration}
            onChange={(e) => handleConfigChange('workDuration', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Rest Duration (minutes)"
            type="number"
            value={tempConfig.restDuration}
            onChange={(e) => handleConfigChange('restDuration', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Work Extension (minutes)"
            type="number"
            value={tempConfig.workExtension}
            onChange={(e) => handleConfigChange('workExtension', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Rest Extension (minutes)"
            type="number"
            value={tempConfig.restExtension}
            onChange={(e) => handleConfigChange('restExtension', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FormControlLabel
            control={
              <Switch
                checked={tempConfig.audioAlerts}
                onChange={(e) => handleConfigChange('audioAlerts', e.target.checked)}
                color="primary"
              />
            }
            label="Enable audio alerts"
          />
          <Box mt={2}>
            <Typography id="volume-slider" gutterBottom>
              Volume
            </Typography>
            <Slider
              value={tempConfig.volume}
              onChange={(e, newValue) => handleConfigChange('volume', newValue)}
              aria-labelledby="volume-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={100}
              disabled={!tempConfig.audioAlerts}
            />
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={tempConfig.blink}
                onChange={(e) => handleConfigChange('blink', e.target.checked)}
                color="primary"
              />
            }
            label="Enable screen flash"
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Theme</InputLabel>
            <Select
              value={tempConfig.selectedTheme}
              onChange={(e) => handleConfigChange('selectedTheme', e.target.value)}
            >
              {themeNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <TodoListConfig
            todoConfig={tempTodoConfig}
            onTodoConfigChange={setTempTodoConfig}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <CalendarConfig
            calendarConfig={tempCalendarConfig}
            onCalendarConfigChange={setTempCalendarConfig}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfigDialog;
